import axios from "axios";
import swal from "sweetalert";
import { loginConfirmedAction, logout } from "../store/actions/AuthActions";

export function signUp(email, password) {
  //axios call
  const postData = {
    email,
    password,
    returnSecureToken: true,
  };
  return axios.post(
    `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    postData
  );
}

export function login(email, password) {
  const postData = {
    email,
    password,
    deviceid: "web-browser",
    platform_type: 1,
    portal_type: 3,
  };
  return axios.post(`https://training-api.wiessolution.com/api/login`, postData);
}

export function forgotpasswordaction(email) {
  const postData = {
    email,
  };
  return axios.post(
    `https://training-api.wiessolution.com/api/forgot_password`,
    postData
  );
}

export function forcepasswordaction(password, token) {
  const postData = {
    password,
  };
  return axios.post(
    `https://training-api.wiessolution.com/api/v1/password_force_update`,
    postData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function changepasswordaction(old_password, password, token) {
  const postData = {
    old_password,
    password,
  };

  return axios.post(
    `https://training-api.wiessolution.com/api/v1/common/password_update`,

    postData,

    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export function formatError(errorResponse) {
  switch (errorResponse) {
    case "SUCCESS":
      //return 'Email already exists';
      swal("Hooray !", errorResponse, "success");
      break;
    case "EMAIL_EXISTS":
      //return 'Email already exists';
      swal("Oops", "Email already exists", "error");
      break;
    case "EMAIL_NOT_FOUND":
      //return 'Email not found';
      swal("Oops", "Email not found", "error", { button: "Try Again!" });
      break;
    case "Unauthorised":
      //return 'Invalid Password';
      swal("Oops", "Invalid Password", "error", { button: "Try Again!" });
      break;
    case "USER_DISABLED":
      return "User Disabled";

    default:
      swal("Oops", errorResponse, "error", { button: "Try Again!" });
  }
}

export function swalalert(errorResponse, bool) {
  switch (bool) {
    case true:
      //return 'Email already exists';
      swal("Hooray !", errorResponse, "success");
      break;
    case false:
      //return 'Email already exists';
      swal("Oops", errorResponse, "error");
      break;

    default:
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  tokenDetails.expireDate = new Date(new Date().getTime() + 1000000 * 1000);
  //console.log(tokenDetails);
  localStorage.setItem("userDetails", JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, history) {
  setTimeout(() => {
    dispatch(logout(history));
  }, timer);
}

export function checkAutoLogin(dispatch, history) {
  //console.log(history);
  const tokenDetailsString = localStorage.getItem("userDetails");
  console.log(tokenDetailsString);
  let tokenDetails = "";
  if (!tokenDetailsString) {
    dispatch(logout(history));
    return;
  }

  tokenDetails = JSON.parse(tokenDetailsString);
  let expireDate = new Date(tokenDetails.expireDate);
  let todaysDate = new Date();

  if (todaysDate > expireDate) {
    dispatch(logout(history));
    return;
  }
  dispatch(loginConfirmedAction(tokenDetails));

  const timer = expireDate.getTime() - todaysDate.getTime();
  runLogoutTimer(dispatch, timer, history);
}
